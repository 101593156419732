import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import * as styles from '../css/header-section.css';
import video from '../assets/videos/video.mp4';
import backgroundImage from '../assets/images/mobileBackground.png';
import HeaderLogoAndLinks from '../components/HeaderLogoAndLinks';
import Header from '../components/Header';

const HeaderSection = () => (
    <div className={styles.container}>
        <Header>
            <HeaderLogoAndLinks variant="main" />
            <Row>
                <Col xs={12} md={8}>
                    <h1 className={styles.bannerText}>
                        Futureproof dispatch, controlling,
                        analysis and passenger information
                        systems for your city
                    </h1>
                </Col>
            </Row>
        </Header>
        <video className={styles.bgVideo} autoPlay muted loop playsInline poster={backgroundImage}>
            <source src={video} type="video/mp4" />
        </video>
    </div>
);

export default HeaderSection;
